@media (max-width:1440px){
   
}
@media (max-width:1280px){
   
}
@media (max-width:1024px){
    .shadeBox {
        display: none;
    }
    .logoImg {
        width: 80px;
    }
}
@media (max-width:991px){
    // .orangeshadeBox {
    //   display: none;
    // }
    // .purpleBox {
    //     display: none;
    // }
    .shadeBox {
        display: none;
    }
    .logoImg {
        width: 80px;
    }
}
@media (max-width:767px){
    // .orangeshadeBox {
    //     display: none;
    // }
    // .purpleBox {
    //     display: none;
    // }
    .shadeBox {
        display: none;
    }
    .logoImg {
        width: 80px;
    }
    .sliderBox img {
        width: auto;
        max-width: 135px;
    }
}