body {
  left: 0;
  top: 0;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  font-family: "Poppins", sans-serif;
  background: #f3f5f7;
}

.displayCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

.displayStart {
  display: flex;
  align-items: center;
  justify-content: start;
  flex-wrap: wrap;
}

.displayEnd {
  display: flex;
  align-items: center;
  justify-content: end;
}

.displaySpacebetween {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.displaySpacearound {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.logoImg {
  width: 110px;
}

.sideMenuBox::-webkit-scrollbar {
  display: none;
}

.sideMenuBox {
  overflow: auto;
  height: calc(100vh - 201px);
}

.MuiBackdrop-root {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  z-index: -1;
  position: fixed;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.75);
  -webkit-tap-highlight-color: transparent;
  -webkit-backdrop-filter: blur(2px);
          backdrop-filter: blur(2px);
}

svg.MuiSvgIcon-root.MuiStepIcon-root.MuiStepIcon-active {
  color: #f39200 !important;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.mainTab {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: rgba(0, 0, 0, 0.05);
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-radius: 50px;
  width: -moz-fit-content;
  width: fit-content;
  padding: 3px;
}
@media (max-width: 767px) {
  .mainTab {
    overflow: auto;
  }
}

.tabActiveButtons {
  background: #0666eb;
  border-radius: 50px;
  padding: 11px 23px;
  color: #fff !important;
  transition: 0.8s;
  cursor: pointer;
  white-space: pre;
  transition: all 500ms ease-in-out;
}

.tabButtons {
  border-radius: 50px;
  padding: 11px 23px;
  white-space: pre;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.4);
}

.sticky-header {
  position: sticky;
  top: -15px;
  z-index: 999;
  background-color: #ffffff; /* Optional: Set background color */
}

@media (max-width: 1024px) {
  .shadeBox {
    display: none;
  }
  .logoImg {
    width: 80px;
  }
}
@media (max-width: 991px) {
  .shadeBox {
    display: none;
  }
  .logoImg {
    width: 80px;
  }
}
@media (max-width: 767px) {
  .shadeBox {
    display: none;
  }
  .logoImg {
    width: 80px;
  }
  .sliderBox img {
    width: auto;
    max-width: 135px;
  }
}/*# sourceMappingURL=main.css.map */